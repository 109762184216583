import React from "react";

export default function ComingSoonPage() {
  return (
    <div className="center-vertically">
      <img
        src="https://cdn.unhurd.co.uk/images/unhurd-logo.png"
        alt="logo"
        width="100"
        height="100"
      />
      <h1>Coming soon</h1>
      <p className="text-faded mt20">Check back later</p>
    </div>
  );
}
