import React from "react";
import "./App.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import ComingSoonPage from "./pages/ComingSoonPage";
import UnderMaintenancePage from "./pages/UnderMaintenancePage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ComingSoonPage />} />
        <Route path="/coming-soon" element={<ComingSoonPage />} />
        <Route path="/under-maintenance" element={<UnderMaintenancePage />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    </div>
  );
}

export default App;
